@use '../base' as *;

header {
  height: 60px;
  background-color: $dark;
  position: relative;

  &::after {
    position: absolute;
    bottom: -5px;
    content: '';
    width: 100%;
    height: 5px;
    background: linear-gradient(to right, rgb(255, 0, 0) 0%, rgb(255, 154, 0) 10%, rgb(208, 222, 33) 20%, rgb(79, 220, 74) 30%, rgb(63, 218, 216) 40%, rgb(47, 201, 226) 50%, rgb(28, 127, 238) 60%, rgb(95, 21, 242) 70%, rgb(186, 12, 248) 80%, rgb(251, 7, 217) 90%, rgb(255, 0, 0) 100%);
    animation: rainbow-border 6s ease-in-out infinite;
    background-size: 400% 100%;
  }

  > nav {
    height: 100%;
    padding: 0 1em;

    .links {
      height: 100%;

    }

    button {
      color: $light;
      cursor: pointer;
      background-color: $dark;
      border: none;
      font-family: $font;
      font-size: $fontSize125;
      font-weight: 700;
      text-transform: uppercase;
      position: relative;

      &:not(:first-child):not(.cta) {
        margin-left: 1.4em;
      }

      &:not(.hamburger):not(.cta):hover::after {
        content: '';
        position: absolute;
        top: 1.75em;
        left: 25%;
        width: calc(50%);
        height: 3px;
        background-color: $secondary-dark;
        background: linear-gradient(to right,
         rgb(255, 0, 0) 0%, rgb(255, 154, 0) 10%, rgb(208, 222, 33) 20%, rgb(79, 220, 74) 30%, rgb(63, 218, 216) 40%, rgb(47, 201, 226) 50%, rgb(28, 127, 238) 60%, rgb(95, 21, 242) 70%, rgb(186, 12, 248) 80%, rgb(251, 7, 217) 90%, rgb(255, 0, 0) 100%);
        animation: rainbow-border 6s ease-in-out infinite;
        background-size: 400% 100%;
      }
    }

    .cta {
      border: 2px solid $light;
      padding: 0;

      &:hover {
        background-color: $light;
      }

      > div {
        height: 100%;
        font: inherit;
        background: linear-gradient(to right, rgb(255, 0, 0) 0%, rgb(255, 154, 0) 10%, rgb(208, 222, 33) 20%, rgb(79, 220, 74) 30%, rgb(63, 218, 216) 40%, rgb(47, 201, 226) 50%, rgb(28, 127, 238) 60%, rgb(95, 21, 242) 70%, rgb(186, 12, 248) 80%, rgb(251, 7, 217) 90%, rgb(255, 0, 0) 100%);
        animation: rainbow-border 6s ease-in-out infinite;
        background-size: 400% 100%;
        background-clip: text;
        -webkit-background-clip: text;
        padding: .4em 1em;

        &:hover {
          color: transparent;
          border-color: transparent;
          background-color: red;
        }
      }
    }

    .hamburger {
      background-color: inherit;
      border: none;
      cursor: pointer;

      &-open {
        
        > span:first-child {
          transform: rotate(45deg);
        }

        > span:nth-child(2) {
          width: 0;
        }

        > span:last-child {
          transform: rotate(-45deg);
        }
      }

      > span {
        display: block;
        width: 26px;
        height: 2px;
        background-color: $light;
        transition: all 300ms ease-in-out;
        transform-origin: center left;
        margin: 0 auto;
    
        &:not(:last-child) {
          margin-bottom: 7px;
        }
      }
    }
  }
}

.mobile-menu__container {
  padding: 10px;
  display: none;
  position: absolute;
  transform: translateY(5px);
  width: 100%;
  background-color: $dark;
  z-index: 101;
  animation-timing-function: ease-in-out;
  animation-duration: 300ms;

  > div > button {
    width: 100%;
    text-align: center;
    color: $light;
    cursor: pointer;
    background-color: $dark;
    border: none;
    padding: 10px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: $fontSize125;

    &:hover {
      color: $secondary;
    }
  }
}

.menu-open {
  display: block;
}

.menu-open-after::after {
  bottom: -156px;
  height: 156px;
  z-index: 100;
}