@use '../base' as *;

section {
  min-height: 25em;
  // padding-bottom: calc(5vw + 3em);
  padding-bottom: 10em;

  @include breakpoint-down(small) {
    padding-bottom: 2.5em;
  }

  > .max-width > h1 {
    font-size: $fontSize300;
    width: fit-content;
    margin: 1.2em auto 0.8em;
  }

  // MAIN
  &:first-child {
    background-color: $dark;
    color: $light;
    height: 100vh;

    .text__container {
      height: 100%;
    }

    h1 {
      font-size: $fontSize400;
    }

    h3 {
      font-size: $fontSize175;
    }
  }
}

#about {
  position: relative;
  padding-bottom: calc(5vw + 7em);

  .introduction-skills {
    gap: 1.5em;
    flex-direction: column;

    @include breakpoint-up(medium) {
      flex-direction: row;
    }

    .introduction {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
  
      > * {
        margin: 0 auto;
        text-align: center;
      }
  
      &__image {
        clip-path: circle(50%);
        min-height: 200px;
        min-width: 200px;

        @include breakpoint-up(medium) {
          min-height: 250px;
          min-width: 250px;
        }
      }
  
      .image__border {
        margin-bottom: 1.5em;
        width: 200px;
        position: relative;

        @include breakpoint-up(medium) {
          width: 250px;
        }
  
        &::after {
          content: "";
          border: 2px dotted $primary;
          border-radius: 50%;
          height: 210px;
          width: 210px;
          display: flex;
          justify-content: center;
          align-items: center;
          animation: spin infinite linear 30s;
          position: absolute;
          top: -5px;
          left: -5px;

          @include breakpoint-up(medium) {
            height: 260px;
            width: 260px;
          }
        }

        &::before {
          content: "";
          border: 2px dashed $secondary-dark;
          border-radius: 50%;
          height: 220px;
          width: 220px;
          display: flex;
          justify-content: center;
          align-items: center;
          animation: spin-backwards infinite linear 30s;
          position: absolute;
          top: -10px;
          left: -10px;

          @include breakpoint-up(medium) {
            height: 270px;
            width: 270px;
          }
        }
      }
  
      > h3 {
        font-size: $fontSize175;
      }
  
      > p {
        font-size: $fontSize110;
      }
    }

    .skills {
      width: 100%;
    }
  }
}

.divider-top {
  width: 100%;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    bottom: calc(-0.75vw);
    width: 100%;
    height: calc(4rem + 8vw);
    transform: skewY(4deg) translateY(calc(2rem + 3vw));
    background-color: $secondary-light;
  }

  &::after {
    content: '';
    position:absolute;
    bottom: calc(-1.75vw);
    width: 100%;
    height: calc(4rem + 8vw);
    transform: skewY(5deg) translateY(calc(2rem + 3vw));
    background-color: $secondary;
  }
}

.divider-bottom {
  width: 100%;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    bottom: calc(-2.25vw);
    width: 100%;
    height: calc(3rem + 8vw);
    transform: skewY(6deg) translateY(calc(2rem + 3vw));
    background-color: $secondary-dark;
    z-index: -1;
  }

  &::after {
    content: '';
    position:absolute;
    bottom: calc(-1.5vw);
    width: 100%;
    height: calc(4rem + 8vw);
    transform: skewY(5deg) translateY(calc(2rem + 3vw));
    background-color: $secondary;
    z-index: -1;
  }
}

#projects {
  padding-top: 0;
  padding-bottom: 1em;
  background-color: $secondary;
  position: relative;

  > .max-width > h1 {
    margin: 0em auto 0.8em;
  }
}

#wip {
  padding-top: 10em;
  padding-bottom: 6vw;
  position: relative;

  > .max-width {
    > h1 {
      margin: 1em auto 0.8em;
    }
  }
}

#contact-me {
  background-color: $dark;
  color: $light;

  > .max-width {

    > h1 {
      font-size: $fontSize300;
      width: fit-content;
      padding: 6rem 0 0;
      margin-bottom: 0.2rem;

      @include breakpoint-down(small) {
        padding: 1rem 0 0;
      }
    }

    > p  {
      margin-bottom: 3rem;
      padding: 0 0 2.5rem;

      @include breakpoint-down(small) {
        padding: 0 0 2rem;
      }
    }

    > form {
      width: 350px;
      margin: 0 auto;

      @include breakpoint-up(medium) {
        width: 450px;
      }

      > div > input, > div > textarea {
        margin-bottom: 1rem;
        background-color: $light;
        border: none;
        border-radius: 2px;
        padding: 0.4rem;
        &::placeholder {
          color: gray;
        }
      }

      > div > label {
        font-weight: 700;
        text-transform: uppercase;
      }

      > div > textarea {
        height: 150px;
        margin-bottom: 1.5rem;
      }

      > .cta {
        border: 2px solid  $light;
        background-color: $dark;

        &:hover {
          background-color: $light;
        }

        > div {
          height: 100%;
          color: $light;
          font-family: $font;
          font-size: $fontSize125;
          font-weight: 700;
          text-transform: uppercase;
          background: linear-gradient(to right, rgb(255, 0, 0) 0%, rgb(255, 154, 0) 10%, rgb(208, 222, 33) 20%, rgb(79, 220, 74) 30%, rgb(63, 218, 216) 40%, rgb(47, 201, 226) 50%, rgb(28, 127, 238) 60%, rgb(95, 21, 242) 70%, rgb(186, 12, 248) 80%, rgb(251, 7, 217) 90%, rgb(255, 0, 0) 100%);
          animation: rainbow-border 6s ease-in-out infinite;
          background-size: 400% 100%;
          background-clip: text;
          -webkit-background-clip: text;
          padding: .4em 1em;
  
          &:hover {
            color: transparent;
            border-color: transparent;
            background-color: red;
          }
        }
      }
    }

    @include breakpoint-down(small) {
      padding: 0.5rem 0 2rem;
    }
  }

  &::before {
    content: '';
    position: absolute;
    left: calc(50% - 10px);
    width: 20px;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid $light;
  }
}