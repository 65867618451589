// COLORS
$dark: #121212;
$darkMedium: #9b9b9b;
$light: #FFFFFF;
$primary: #800000;
$secondary: #87CEFA;
$secondary-light: #DBF1FF;
$secondary-dark: #53BDFF;
$secondary-darker: hsl(203, 100%, 50%);

// FONT
$font: 'Rubik', sans-serif;
$fontSize100: 0.9375rem;
$fontSize110: 1.03125rem;
$fontSize125: 1.171875rem;
$fontSize150: 1.40625rem;
$fontSize175: 1.640625rem;
$fontSize200: 1.875rem;
$fontSize250: 2.34375rem;
$fontSize300: 2.8125rem;
$fontSize400: 3.75rem;