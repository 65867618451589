@use '../base' as *;

.cards {
  gap: 1em;
  flex-direction: row;
  flex-wrap: wrap;

  @include breakpoint-up(medium) {
    width: 500px;
  }

  @include breakpoint-up(large) {
    width: 100%;
  }
}

.card {
  height: 262px;
  min-width: 350px;
  width: 350px;
  border-radius: 10px;
  background-color: $secondary-darker;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  // align-items: center;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.card__details {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.card__description {
  text-align: center;
  font-weight: 600;
  font-size: $fontSize125;
  margin: 2em 2em 0em;
  color: $light;
}

.card__description--wip {
  margin: 0 2em;
}

.card__image {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  z-index: 3;
}

.card__button {
  border: 2px solid $light;
  padding: 0;
  margin-bottom: 2em;

  &:hover {
    background-color: $light;
    border-color: $light;
  }

  > div {
    height: 100%;
    text-transform: uppercase;
    font-family: $font;
    font-size: $fontSize125;
    font-weight: 700;
    color: $light;
    background: linear-gradient(to right, rgb(255, 0, 0) 0%, rgb(255, 154, 0) 10%, rgb(208, 222, 33) 20%, rgb(79, 220, 74) 30%, rgb(63, 218, 216) 40%, rgb(47, 201, 226) 50%, rgb(28, 127, 238) 60%, rgb(95, 21, 242) 70%, rgb(186, 12, 248) 80%, rgb(251, 7, 217) 90%, rgb(255, 0, 0) 100%);
    animation: rainbow-border 6s ease-in-out infinite;
    background-size: 400% 100%;
    background-clip: text;
    -webkit-background-clip: text;
    padding: .4em 1em;

    &:hover {
      color: transparent;
      border-color: transparent;
      background-color: red;
    }
  }
}