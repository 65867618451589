*, *::before, *::after {
  box-sizing: border-box;
}

ul {
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

body, ul[class], li,
p, h1, h2, h3, h4, h5 {
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.3;
}

img {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;

  &:focus {
    outline: 0;
  }
}