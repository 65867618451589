@use '../base' as *;

.skillbar {
  &__wrapper {
    height: 30px;
    width: 100%;
    display: grid;
    grid-template-areas: "left right";
    background-color: rgb(226, 226, 226);
    
    &:not(:first-child) {
      margin-top: 12px;
    }
  }

  &__left {
    height: 100%;
    grid-area: left;
    background-color: #87CEFA;
  }

  &__right {
    height: 100%;
    grid-area: right;
    background-color: transparent;
  }

  &__title-bar {
    line-height: 30px;
    width: 100px;
    background-color: #53BDFF;
    text-align: center;
    font-weight: 700;
    color: #F5F5F5;
    text-shadow: 1px 1px #121212;
  }

  &__percentage-bar {
    padding-right: 10px;
    float: right;
    line-height: 30px;
    color: #121212;
  }
}