@use '../base' as *;

.cloud {
  width: 100%;
  position: absolute;
  background-repeat: no-repeat;
  background-size: auto 100%;
  animation-name: floatX;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  opacity: 0;
}

.cloud-small {
  background-image: url(https://sergiudeaj.ro/assets/images/cloud-small.png);
  min-height: 10px;
}

.cloud-big {
  background-image: url(https://sergiudeaj.ro/assets/images/cloud-big.png);
  min-height: 20px;
}

.bottom-button {
  height: 40px;
  width: 40px;
  background-color: #FF5722;
  color: $light;
  position: absolute;
  left: calc(50% - 20px);
  border: none;
  cursor: pointer;
  bottom: 20px;
  z-index: 1000;
  opacity: 0;
  animation: late-fade-in 0.5s ease-in-out 5s forwards, jump 10s ease-in-out 5s infinite;
  border-radius: 10px;

  &:hover {
    background-color: rgb(255, 129, 91);
  }

  > .fas::before, > .fas::after {
    font-size: $fontSize175;
  }
}