@use '../base' as *;

footer {
  background-color: rgb(0, 0, 0);
  padding: 6em 0 2em;
  position: relative;

  @include breakpoint-down(small) {
    padding: 4em 0 2em;
  }

  > * {
    color: $light;
  }

  p {
    text-align: center;
  }

  .top-button {
    height: 40px;
    width: 40px;
    background-color: $secondary-dark;
    position: absolute;
    left: calc(50% - 20px);
    border: none;
    cursor: pointer;
    top: -20px;
    border-radius: 10px;

    > .fas::before, > .fas::after {
      font-size: $fontSize175;
    }
  }
}

.buttons {
  margin: 0 auto;
  width: 300px;
  height: 50px;
  gap: 1em;
  margin-bottom: 1.5em;

  > .button {
    height: 60px;
    width: 60px;
    border: 2px solid $light;

    &:hover {
      background-color: $dark;
    }

    > .fab {
      color: $light;
      &::after, &::before {
        font-size: 2.5em;
      }
    }
  }
}