@use './variables' as *;
@use './mixins' as *;

*, *::after, *::before {
  font-size: $fontSize100;
}

.flex {
  display: flex;

  &-fd-r {
    flex-direction: row;
  }

  &-fd-c {
    flex-direction: column;
  }

  &-jc-c {
    justify-content: center;
  }

  &-jc-sb {
    justify-content: space-between;
  }

  &-jc-sa {
    justify-content: space-around;
  }

  &-ai-c  {
    align-items: center;
  }

  &-ai-sb {
    align-items: stretch;
  }
}

.hide-for-mobile {
  @include breakpoint-down(small) {
    display: none;
  }
}

.hide-for-desktop {
  @include breakpoint-up(medium) {
    display: none;
  }
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;

  & + section {
    padding-top: 65px;
  }
}

.max-width {
  max-width: 1200px;
  margin: 0 auto;
}

.responsive-padding {
  padding: 0 1em;

  @include breakpoint-up(x-large) {
    padding: 0;
  }
}

.fade-out {
  animation: fade-out 0.5s ease-in-out forwards;
}

.fade-in {
  animation: fade-in 0.5s ease-in-out forwards;
}

.hide {
  display: none;
}

.show {
  display: block;
}