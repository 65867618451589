@use '../base' as *;

.shapes {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 0 0 4em;
  margin: 0;
  gap: 1vw;

  @include breakpoint-up(medium) {
    flex-wrap: nowrap;
  }
}

.shape {
  align-items: center;
  justify-content: center;
  display: flex;
  height: 43vw;
  width: 43vw;
  max-height: 22em;
  max-width: 22em;
  position: relative;

  @include breakpoint-up(medium) {
    height: 23vw;
    width: 23vw;
    max-height: 18em;
    max-width: 18em;
  }

  > * {
    animation-duration: 15s;
    animation-iteration-count: infinite;    
    animation-timing-function: cubic-bezier(.17,.67,.83,.67);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:nth-child(1) {
    .shape--large {
      animation-name: morph-1;
      background: #DCEDC8;
    }
    .shape--medium {
      animation-name: morph-2;
      background: #8BC34A;
    }
    .shape--small {
      animation-name: morph-3;

      .shape__title { 
        color: #8BC34A;
      }
    }
  }

  &:nth-child(2) {
    .shape--large {
      animation-name: morph-4;
      background: #FFECB3;
    }
    .shape--medium {
      animation-name: morph-5;
      background: #FFC107;
    }
    .shape--small {
      animation-name: morph-6;

      .shape__title { 
        color:  #FFC107;
      }
    }
  }

  &:nth-child(3) {
    .shape--large {
      animation-name: morph-7;
      background: #FFCCBC;
    }
    .shape--medium {
      animation-name: morph-8;
      background: #FF5722;
    }
    .shape--small {
      animation-name: morph-9;

      .shape__title { 
        color: #FF5722;
      }
    }
  }

  &:nth-child(4) {
    .shape--large {
      animation-name: morph-10;
      background: #DBB0FF;
    }
    .shape--medium {
      animation-name: morph-1;
      background: #A546F3;
    }
    .shape--small {
      animation-name: morph-4;

      .shape__title { 
        color: #A546F3;
      }
    }
  }
}

.shape--large { 
  transition: all 5s ease-in-out;
  height: 98%;
  width: 98%;
}

.shape--medium {
  transition: all 5s ease-in-out;
  height: 87.5%;
  width: 87.5%;
}

.shape--small {
  transition: all 5s ease-in-out;
  align-items: center;
  background: #212121;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 75%;
  width: 75%;
}

.shape__title {
  display: block;
  color: #f1f1f1;
  font-family: var(--ff-primary);
  font-weight: 700;
  font-size: 4.3vw;
  text-align: center;
  text-transform: uppercase;

  @include breakpoint-up(medium) {
    font-size: 2.2vw;
  }

  @include breakpoint-up(large) {
    font-size: 1.7em;
  }
}

.shape__text {
  display: block;
  color: #f1f1f1;
  font-family: var(--ff-primary);
  font-size: 3vw;
  text-align: center;
  padding: 0 1em;

  @include breakpoint-up(medium) {
    font-size: 1.7vw;
  }

  @include breakpoint-up(large) {
    font-size: 1.2em;
  }

  @include breakpoint-down(x-small) {
    font-size: 0.8em;
  }
}
