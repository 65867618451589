// 640px, 1024px, 1200px
$breakpoints-up: ("medium": "40em", "large": "64em", "x-large": "75em");
@mixin breakpoint-up($size) {
  @media(min-width: map-get($breakpoints-up, $size)) {
    @content
  }
};

//374px, 639px, 1023px;
$breakpoints-down: ("x-small": "23.375em", "small": "39.9375em", "medium": "63.9375em");
@mixin breakpoint-down($size) {
  @media(max-width: map-get($breakpoints-down, $size)) {
    @content
  }
};

$shapes:  polygon(93% 0, 100% 84%, 13% 100%, 0 11%),
          polygon(100% 0, 94% 100%, 13% 100%, 0 11%),
          polygon(95% 0, 100% 88%, 0 100%, 4% 9%),
          polygon(100% 0, 100% 84%, 3% 100%, 0 15%),
          polygon(90% 0, 100% 95%, 0 100%, 6% 0),
          polygon(100% 0, 89% 100%, 0 100%, 8% 4%),
          polygon(100% 6%, 100% 91%, 6% 100%, 0 0),
          polygon(100% 4%, 89% 100%, 4% 100%, 0 1%),
          polygon(100% 16%, 94% 100%, 3% 100%, 0 0),
          polygon(100% 8%, 96% 90%, 6% 100%, 0 0);

@mixin get-shape($index) {
  clip-path: nth($shapes, $index);
}