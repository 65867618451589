@use './mixins' as *;

@keyframes rainbow-border {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 100% 0;
  }
  100% {
    background-position: 0 0;
  }
}

@for $i from 1 through 10 {
  @keyframes morph-#{$i} {
    0% { @include get-shape($i); }
    20% { @include get-shape((($i + 1) % 10) + 1); }
    40% { @include get-shape((($i + 2) % 10) + 1); }
    60% { @include get-shape((($i + 3) % 10) + 1); }
    80% { @include get-shape((($i + 4) % 10) + 1); }
    100% { @include get-shape($i); }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin-backwards {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes opacity-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100%;
  }
}

@keyframes opacity-out {
  from {
    opacity: 100%;
  }
  to {
    opacity: 0;
  }
}

@keyframes floatX {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  1% {
    transform: translateX(100%);
    opacity: 1;
  }
  100% {
    transform: translateX(-15%);
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
    z-index: 3;
  }
  35% {
    opacity: 0.95;
    transform: scale(1.05);
    z-index: 3;
  }
  100% {
    opacity: 0;
    z-index: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
    z-index: 1;
  }
  1% {
    z-index: 3;
  }
  100% {
    opacity: 1;
    z-index: 3;
  }
}

@keyframes late-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes jump {
  0%, 47% {
    transform: scale(1)
  }
  50% {
    transform: scale(1.3)
  }
  52%, 100% {
    transform: scale(1)
  }
}