@use '../base' as *;

#message-box-area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 75%);
}

#message-box {
  position: fixed;
  width: 360px;
  height: 180px;
  background-color: $light;
  top: calc(50% - 90px);
  left: calc(50% - 180px);
  overflow: hidden;
}

#message-box__header {
  text-align: center;
  background-color: hsl(0, 100%, 65%);
  color: $light;
  font-weight: 500;
  font-size: 1.17rem;
  width: 100%;
  padding: 15px 10px;
}

#message-box__text {
  text-align: center;
  padding: 10px;
  margin-top: 21px;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

#message-box__button {
  margin: 10px auto;
}

.btn--cta {
  border: 2px solid $secondary-darker;
  border-radius: 5px;
  background-color: $light;
  color: $secondary-darker;
  padding: 0.5em 1em;
  width: fit-content;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background-color: $secondary-darker;
    color: $light;  
  }
}

.btn--close {
  border: none;
  color: $light;
  background-color: transparent;
  position: absolute;
  top: 15px;
  right: 10px;
  cursor: pointer;
  
  > .fa-times::before {
    font-size: 1.17rem;
  }

  &:hover {
    color: $dark;
  }
}